<template>
	<div class="remind">
		<Header></Header>
		<div class="wrapper">
			<div class="main-title">提醒列表</div>
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="保养提醒" name="1">
				</el-tab-pane>
				<el-tab-pane label="保险提醒" name="2">
				</el-tab-pane>
				<el-tab-pane label="年审提醒" name="3">
				</el-tab-pane>
                <el-tab-pane label="预约提醒" name="4">
                </el-tab-pane>
			</el-tabs>
			<remindList :remindList="remindList" :type="activeIndex"></remindList>
			<el-pagination background layout="prev, pager, next" :page-size="search.size"
				:current-page.sync="search.page" :pager-count="5" :total="total">
			</el-pagination>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import http from '../../http.js'
	import Header from "../../components/header.vue"
	import Footer from "../../components/footer.vue"
	import remindList from "./components/remindList.vue"
	export default {
		name: 'Home',
		components: {
			Header,
			Footer,
			remindList
		},
		data() {
			return {
				activeName: "1",
				activeIndex: 0,
				search: {
					page: 1,
					size: 20
				},
				total: 0,
				remindList: []
			}
		},
		created() {
			if(this.$route.query.activeName){
				let activeValue =this.$route.query.activeName
				this.activeName = activeValue + ''
				this.activeIndex = activeValue - 1
			}
			console.log(this.activeName)
			this.getList()
		},
		methods: {
			getList(){
				if(this.activeIndex == 0){
					this.handleMaintain()
				}else if(this.activeIndex == 1){
					this.handleInsurance()
				}else if(this.activeIndex == 2){
					this.handleInspection()
				}else{
                    this.handleAppointment()
                }
			},
			handleMaintain(){
				http.fetchGet('/api/v2/admin/car-check/maintain/list', this.search).then((res) => {
					if (res.data.code == 0) {
						let list = res.data.data.list
						this.remindList = []
						list.forEach(item => {
							this.remindList.push({
								id: item.id,
								title: item.number + " - 保养提醒",
								time: '下次保养: 还剩' +(item.next_mileage-item.mileage) + '公里',
								content: '下次保养还剩' + (item.next_mileage-item.mileage) + '公里！请及时到店里进行保养维修。',
								read: item.maintain_tip == 1
							})
						})
					} else {
						this.$message.error(res.data.desc)
					}
				})
			},
			handleInsurance(){
				http.fetchGet('/api/v2/admin/car-check/insurance/list', this.search).then((res) => {
					if (res.data.code == 0) {
						let list = res.data.data.list
						this.remindList = []
						list.forEach(item => {
							this.remindList.push({
								id: item.id,
								title: item.number + " - 保险到期提醒",
								time: '到期时间: ' + item.insuranceDate,
								content: item.number + '保险将于 ' + item.insuranceDate + ' 到期！请及时进行续保。',
								read: item.insurance_tip == 1
							})
						})
					} else {
						this.$message.error(res.data.desc)
					}
				})
			},
			handleInspection(){
				http.fetchGet('/api/v2/admin/car-check/inspection/list', this.search).then((res) => {
					if (res.data.code == 0) {
						let list = res.data.data.list
						this.remindList = []
						list.forEach(item => {
							this.remindList.push({
								id: item.id,
								title: item.number + " - 年审到期提醒",
								time: '到期时间: ' + item.inspectionDate,
								content: item.number + '年审将于 ' + item.inspectionDate + ' 到期！请及时进行车辆年审。',
								read: item.inspection_tip == 1
							})
						})
					} else {
						this.$message.error(res.data.desc)
					}
				})
			},
            handleAppointment(){
                http.fetchGet('/api/v2/admin/car-check/appointment/list', this.search).then((res) => {
                    if (res.data.code == 0) {
                        let list = res.data.data.list
                        this.remindList = []
                        let typeList = ['维修', '保养', '救援', '上门服务', '销售']
                        list.forEach(item => {
                            this.remindList.push({
                                id: item.id,
                                title: item.number + " - 预约" + typeList[item.type-1],
                                time: '预约时间: ' + item.appointmentTime,
                                content: item.number + '预约' + typeList[item.type-1] + ' 预约门店：' + (item.store ? item.store : '无'),
                                read: item.inspection_tip == 1
                            })
                        })
                    } else {
                        this.$message.error(res.data.desc)
                    }
                })
            },
			handleClick(e){
				console.log(e)
				this.activeIndex = e.index
				this.search.page = 1
				this.getList()
			}
		}
	}
</script>
<style lang="scss">
	body {
		background: $main-bg-color;
	}
</style>
