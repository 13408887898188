<template>
	<div>
	<div class="remind-list">
		<ul>
			<li class="shadow" v-for="(item) in remindList"
				:key="item.id" @click="remindClick(item)">
				<div class="top">
					<div class="remind-name">
						<el-badge is-dot :hidden="item.read">
							<i class="el-icon-message-solid"></i>
						</el-badge>
						<span>{{item.title}}</span>
					</div>
					<div class="right">
						<span class="time">{{item.time}}</span>
						<el-tag type="success" effect="dark" size="mini" v-if="item.read == 1">已提醒</el-tag>
						<el-tag type="danger" effect="dark" size="mini" v-if="item.read == 0">未提醒</el-tag>
						<i class="el-icon-arrow-right"></i>
					</div>
				</div>
				<div class="content line2">
					{{item.content}}
				</div>
			</li>
		</ul>
	</div>
			
	</div>
</template>

<script>
	export default{
		data(){
			return{
			}
		},
		props: {
			'remindList': Array,
			'type': Number
		},
		methods:{
			remindClick(item){
				this.$router.push("/remindDetail?id="+item.id+"&type="+this.type)
			}
		}
	}
</script>

<style lang="scss">
	.el-tabs__item{
		font-size: 18px;
	}
</style>
